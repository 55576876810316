<template>
  <div class="sitedetail siteDetailWarp">
    <div @click="siteInfoClick">
      <header-site-info bg-color="#63B57E" :masterName="masterName" :siteName="siteName" :siteNo="areaNo" :siteDepart="siteDepartment"></header-site-info>
    </div>

    <!-- <div class="change-info flex">
      <div class="block info-left flex" @click="siteinfoChange">
        <img :src="siteblue" class="siteblue-img">
        <div>修改村情信息</div>
      </div>
      <div class="block info-right flex" @click="managerinfoChange">
        <img :src="managerInfo" class="managerInfo-img">
        <div>修改站点信息</div>
      </div>
    </div> -->

    <div class="content">
      <van-field label="站点状态" :value="siteStatus" readonly input-align="right"/>
      <van-field label="站点管理责任人" label-width="150px" :value="marketerName" readonly input-align="right"/>
      <van-field label="合作业务" placeholder="请选择合作业务" :value="cooBiz" label-width="1.4rem" readonly input-align="right" right-icon="arrow" @click="cooBizShow = true" />
      <van-field label="合作银行" placeholder="请选择合作银行" :value="bank" label-width="1.4rem" readonly input-align="right" right-icon="arrow" @click="bankShow = true" type="textarea" rows="1" autosize v-show="cooBizval == 'AGRI_FINANCE'"/>
    </div>
    <div class="line12"></div>
    <div class="content-title">签约信息</div>
    <div class="content">
      <van-field label="签约日期" :value="signDate" readonly input-align="right"/>
      <van-field label="参与人员" :value="userPeople + peoples" readonly input-align="right" right-icon="plus" @click="peoplesClick"/>
    </div>
    <div class="line12"></div>

    <div class="content-title">图片上传（限制3张）</div>
    <div class="card-upload">
      <van-uploader v-model="fileList" accept="image/*" max-count="3"  :after-read="afterRead" :before-delete="deletePic"/>
    </div>
    <div class="content-desc">上传站长全身照片和站点场所照片</div>
    <div class="line12"></div>
    <div class="content-title ">工作备注</div>
    <van-field  class="workNote"
      v-model="workRemark"
      rows="2"
      autosize
      type="textarea"
      placeholder="填写备注信息（5-50字）"
      maxlength="50"
    />
    <div class="agreement flex" v-if="isShow_Join_Join">
      <img :src="feedbackImg" style="width:0.3rem; height:0.3rem" v-if="isSign == 1">
      <van-checkbox v-else checked-color="#3F7C53" v-model="checked" shape="round" icon-size="0.3rem"></van-checkbox>
      <div>签署<span @click="protocolClick('AGT000')" style="color: #3F7C53">《村口壹站加盟合作协议》</span> </div>
    </div>

    <div class="nextBtn" v-if="isShow_Join_Join">
      <van-button class="publicBtnActive"  slot="button" type="info" color="#3F7C53" @click="buttonClick">提交加盟</van-button>
    </div>

    <van-popup v-model="bankShow" position="bottom">
      <van-picker
        :columns="bankList"
        show-toolbar
        @cancel="bankPickerCancel"
        @confirm="bankPickerConfirm"
      />
    </van-popup>

    <van-popup v-model="cooBizShow" position="bottom">
      <van-picker
        :columns="selectList"
        show-toolbar
        @cancel="cooBizCancel"
        @confirm="cooBizConfirm"
      />
    </van-popup>

    

    <van-dialog v-model="show" title="提示" confirmButtonColor="#3F7C53" width="5.6rem" className="dialog">
      <div style="margin: 0.24rem 0.24rem 0.54rem; font-size:0.3rem; color:#333; ">{{showMsg}}</div>
    </van-dialog>
    
    <div v-if="overlayshow" class="overlay-class">
      <partnerlist :deptNo="deptNo" @overlayClose="overlayClose" :peoplesstr="peoplesstr"></partnerlist>
    </div>
      

  </div>
</template>

<script>
import { getParterByDeptNo, deleteFileByFileNo, submitJoin, getPicByTypeAndNo, getAgreementInfoByType, getBranchList, getJoinInfoByStationNo, getContractByNo, getDictByPage } from '@/utils/api'
import common from '@/utils/common'
import JsZip from "jszip";
import {uploadZipAndAlreadyImg} from "@/utils/api";
import HeaderSiteInfo from "../../components/siteInfo/headerSiteInfo";
import {appAction, uwStatisticAction} from "@/utils/util";
import {mapGetters} from 'vuex'
import {STATION_JOIN_FINISHED,Home_Page} from "@/utils/constantNum";
import partnerlist from './partnerlist'
export default {
  name:'sitedetail',
  components:{
    HeaderSiteInfo,
    partnerlist,
  },
  computed:{
    ...mapGetters([
      'isShow_Join_Join'
    ])
  },
  data() {
    return {
      logoImg: require('../../assets/img/logo_f.png'),
      siteName: '',
      areaNo: '',
      siteDepartment: '',
      siteStatus: '考察中',
      bank: '', //中国工商银行漕河泾支行
      bankVal: '',
      bankShow: false,
      bankList: [],
      signDate: common.time(new Date()),
      peoples: '',
      peoplesVal: '',
      partnerList: [],
      fileList: [],
      fileNoList:[],
      workRemark: '',
      checked: false,
      participant:[],
      participantList:[],
      maxSubType: null,
      userPeople: '',
      idCard: '',
      villageCode: '',
      deptNo: '',
      fromFlag: true,
      uniqueNo:'',
      uploadZipImgList:[],
      fileImgUrls:[],
      marketerName:'',
      masterName:'',
      show: false,
      showMsg: '',
      overlayshow: false,
      peoplesstr:'',
      siteblue: require('@/assets/img/site/siteblue.png'),
      managerInfo: require('@/assets/img/site/managerInfo.png'),
      feedbackImg: require('@/assets/img/mine/successIcon.png'),
      electronContractNo: null,
      isSign: null,
      loginNo: '',
      tempuserpeople: '',
      clicFlag: false,
      masterNo: '',
      selectList:[],
      selectAllList: [],
      cooBiz:'',
      cooBizval: '',
      stationFlag: '',
      cooBizShow: false,

    }
  },
  filters: {
    ellipsis (value) {
      if (!value) return ''
      if (value.length > 16) {
        return value.slice(0,16) + '...'
      }
      return value
    }
  },
  watch:{
    checked(newVal){
      if(newVal == true){
        if(!common.getItem('signZZ')){
          this.protocolClick('AGT000')
        }
      }
    },
  },
  methods: {
    cooBizConfirm(val, index){
      this.cooBiz = val
      this.cooBizval = this.selectAllList[index].dictKey
      this.cooBizShow = false
      if(this.cooBizval == 'AGRI_FINANCE'){
        this.bankVal = ''
        this.bank = ''
      }
    },
    cooBizCancel() {
      this.cooBizShow = false
    },
    siteinfoChange(){
      this.$router.push({name: 'newsiteInfo', query:{hasSave : '1',stationNo: this.$route.query.stationNo}})
    },
    managerinfoChange() {
      this.$router.push({name: 'newmanagerInfo', query:{hasSave : '1', masterNo: this.uniqueNo }})
    },
    togetJoinInfoByStationNo(){
      getJoinInfoByStationNo({
        stationNo: this.$route.query.stationNo
      }).then(res=>{
         if(res.code == 200){
          if(res.data && res.data.businessCode == 200){
            common.setItem('masterNo', res.data.data.uniqueNo)
            this.siteName = res.data.data.stationName
            this.areaNo = res.data.data.areaNo
            this.siteDepartment = res.data.data.deptName
            this.idCard = res.data.data.idCard
            this.villageCode = res.data.data.villageCode
            this.deptNo = res.data.data.stationDept
            this.uniqueNo = res.data.data.uniqueNo
            this.masterNo = res.data.data.uniqueNo
            this.masterName = res.data.data.masterName
            this.marketerName = res.data.data.marketerName
            this.userPeople = res.data.data.loginName
            this.tempuserpeople = res.data.data.loginName
            this.loginNo = res.data.data.loginNo
            this.electronContractNo =res.data.data.electronContractNo 
            this.isSign = res.data.data.isSign
            this.cooBizval = res.data.data.coopBiz
            this.stationFlag = res.data.data.stationFlag
            this.togetDictByPage()

            // 数据回显
            if(res.data.data.participant){
              let a = res.data.data.participant.split(',')
              a.shift()
              this.userPeople = res.data.data.loginName + ','
              this.peoples = a.toString()
            }

            if(res.data.data.cooperationBank){
              this.bank = res.data.data.cooperationBank
              this.bankVal = res.data.data.cooperationBankCode
            }

            this.getPartnerList()
            common.setItem('masterNo', res.data.data.uniqueNo)
            this.clicFlag = true
          }
        }
      })
    },
    siteInfoClick() {
      this.$router.push({name:'siteInfo', query: {stationNo: this.$route.query.stationNo}})
    },
    // 获取图片接口，针对页面刷新
    getPic() {
      getPicByTypeAndNo({
        fileType: 'signPic',
        stationNo: this.$route.query.stationNo,
        token: common.getItem('wtToken')
      }).then(res=>{
        this.fileNoList = res.data.picList
        this.maxSubType = res.data.maxSubType
        for(let i= 0; i< this.fileNoList.length; i++){
          this.fileList.push({url:''})
          this.fileImgUrls.push('')
        }
        for(let [i,obj] of this.fileNoList.entries()){
          this.fileImgUrls[i] = obj.fileNo
          if(process.env.NODE_ENV == 'production'){
            this.fileList[i].url = window.location.origin + '/file/' + obj.pic  + '?Authorization=' + common.getItem('wtToken')
            this.fileList[i].fileNo = obj.fileNo
          }else{
            this.fileList[i].url = 'http://124.70.167.116:20081' + '/file/' + obj.pic  + '?Authorization=' + common.getItem('wtToken')
            this.fileList[i].fileNo = obj.fileNo

          }
        }
      })
    },
    afterRead(file, detail) {
      // 大于15M
      if(file.file.size > 15 * 1024 * 1024){
        this.$toast('图片太大，请上传小于15M的图片')
        return
      }
      if(!common.imageVerify(file.file.type)){
        this.fileList.splice(detail.index, 1)
        this.$toast('上传图片必须为png或者jpg格式')
        return
      }
      // 小于100K不进行压缩
      let _this = this
      if(file.file.size < 100 * 1024){
        console.log("小图片")
        let namearr = file.file.name.split('.')
        let name = '.'+namearr[namearr.length-1]
        _this.uploadZipImgList.push({data:file.content,name:name})
        return
      }
      let reader = new FileReader()
      reader.readAsDataURL(file.file)

      reader.onload = function (e) {
        let content= file.content //图片的src，base64格式
        let img = new Image()
        let namearr = file.file.name.split('.')
        let name ='.'+namearr[namearr.length-1]
        img.src = content
        img.onload = function() {
          console.log("压缩图片")
          common.compress(img, (dataURL)=>{
            _this.uploadZipImgList.push({data:dataURL,name:name})
          })
        }
      }
    },
    deletePic(file, pic) {
      console.log("aaaa:删除图片:",pic.index)
      console.log("aaaa:file:",file)
      this.fileList.splice(pic.index,1)
      if (file.fileNo){
        deleteFileByFileNo({
          fileNo: file.fileNo,
          token: common.getItem('wtToken'),
        }).then(res=>{
          this.fileImgUrls.splice(pic.index,1)
        })
      }else {
        this.uploadZipImgList.splice(pic.index - this.fileImgUrls.length,1)

      }
    },
    bankPickerConfirm(val, i) {
      this.bank = val[0] + val[1] + val[2]
      this.bankVal = this.bankList[i[0]].children[i[1]].children[i[2]].code
      common.setItem('bank', this.bank)
      common.setItem('bankVal', this.bankVal)
      this.bankShow = false
    },
    bankPickerCancel() {
      this.bankShow = false
    },
    protocolClick(agreementNo) {
      if(!this.clicFlag){
        this.$toast('请稍等，数据正在加载')
        return
      }
      if(this.isSign == 1){  // 已签约
        getContractByNo({
          electronContractNo: this.electronContractNo
        }).then(res=>{
          common.setItem('signZZ', true)
          window.location.href = res.data.data
        })
      }else{
         getAgreementInfoByType({
          token: common.getItem('wtToken'),
          agreementNo,
           stationNo: this.$route.query.stationNo,
          uniqueNo: this.uniqueNo
        }).then(res=>{
          if(res.code == 200){
            if(res.data && res.data.businessCode == 200){
              this.$router.push({path:res.data.data.agreementPath, query:{
                sign: 'sign',
                stationNo: this.$route.query.stationNo,
                redirect: 'sitedetail',
                partner: this.peoples,
                agreementParams: res.data.data.agreementParams,
                masterNo: this.masterNo
              }})
            }
          }
        })
      }
    },
    peoplesClick() {
      this.overlayshow = true
      this.peoplesstr = this.peoples
    },
    overlayClose(data){
      this.overlayshow = false
      if(data.length == 0){
        this.userPeople = this.tempuserpeople
        this.peoples = ''
      }else{
        this.userPeople = this.tempuserpeople + ','
        this.peoples = data.toString()
      }
    },
    buttonClick() {
      console.log("提交图片：",this.fileList)
      console.log("网络图片：",this.fileImgUrls)
      console.log("本地图片：",this.uploadZipImgList)
      if(!this.cooBizval){
        this.$toast('请选择合作业务')
        return
      }
      if(this.cooBizval=='RECEIVE_EXPRESS'){
        this.$toast('快递收发业务暂不支持！')
        return
      }
      if(this.cooBizval == 'AGRI_FINANCE'){
        if(!this.bankVal){
          this.$toast('请选择合作银行')
          return
        }
      }else{
        this.bankVal = ''
        this.bank = ''
      }
      
      // if(this.peoples.split(',').length > 2){
      //   this.$toast('参与人员最多2个')
      //   return
      // }
      console.log('this.peoples', this.peoples)
      this.participant = []
      if(this.peoples){
        for(let obj of this.participantList){
          for(let val of this.peoples.split(',')){
            if(obj.userName == val){
              this.participant.push(obj.userNo)
            }
          }
        }
      }
      if(!this.stationFlag) {
        if (this.fileList.length == 0) {
          this.$toast('请上传图片')
          return
        }
        if (this.fileList.length < 2 || this.fileList.length > 3) {
          this.$toast('图片请上传2-3张')
          return
        }
        if (this.workRemark && (this.workRemark.length < 5 || this.workRemark.length > 50)) {
          this.$toast('工作备注字段长度为5-50')
          return
        }
      }
      if(this.isSign == 1){
        if(!this.checked){
          this.$toast('请阅读村口壹站加盟协议')
          return
        }
      }else{
        if(!this.checked){
          this.$toast('请勾选已签署协议')
          return
        }
      }
      
      if(!common.getItem('signZZ')){
        this.$toast('请点击协议并签署')
        return
      }

      let self = this

      let agreementNo = [{agreementNo: 'AGT000'}]

      var zip = new JsZip();
      var img = zip.folder("images");
      for (var i=0;i<this.uploadZipImgList.length;i++){
        console.log("mingzi:",this.uploadZipImgList[i].name)
        img.file("signPic_"+ i+this.uploadZipImgList[i].name, this.uploadZipImgList[i].data.substring(this.uploadZipImgList[i].data.indexOf(",")+1), {base64: true});
      }
      let json = {'signPic':self.fileImgUrls}
      let fileAleradyJson =JSON.stringify(json)
      zip.generateAsync({type:"blob"}).then(function(content) {
        console.log("上传图片到服务器")
        uploadZipAndAlreadyImg(content, self.$route.query.stationNo,fileAleradyJson).then(res=>{
          if (res.code == 200){
            if (res.data.businessCode && res.data.businessCode === 200) {
              console.log('上传图片成:',submitJoin)
             self.submitJoinAction(agreementNo)

            }
          }
        }).catch(()=>{
        })

      },e=>{
        this.$toast('上传图片异常')
        console.log('error',e)
      });
     /**/
    },
    getBankList() {
      this.bankList = [];
      getBranchList({
        token: common.getItem('wtToken'),
      }).then(res=>{
        if(res.code === 200){
          if(res.data && res.data.businessCode === 200){
            this.bankList = res.data.data
            if(common.getItem('bank') && common.getItem('bankVal')){
              this.bank = common.getItem('bank') 
              this.bankVal = common.getItem('bankVal')
            }
          }
        }
      })
    },
    getPartnerList() {
      this.participant = []
      getParterByDeptNo({
        token: common.getItem('wtToken'),
        deptNo: this.deptNo,
        userName: ''
      }).then(res=>{
        if(res.code === 200){
          if(res.data && res.data.businessCode === 200){
            this.participantList = res.data.data.parterList
          }
        }
      })
    },
    submitJoinAction(agreementNo){
      console.log('kkkkk:',common.getItem('pageType'))
      let participant = ''
      participant = this.participant.length == 0 ? this.loginNo : this.loginNo + ','+ this.participant.toString()
      submitJoin({
        token: common.getItem('wtToken'),
        stationNo: this.$route.query.stationNo, // 站点编号
        cooperationBank: this.bankVal, // 合作银行
        signDate: this.signDate, // 签约日期
        participant: participant,
        coopBiz: this.cooBizval,
        workNote: this.workRemark ? this.workRemark : '无',
        uniqueNo: this.uniqueNo,
        agreementList: agreementNo
      }).then(res=>{
        if(res.code == 200){
          if(res.data && res.data.businessCode == 200){
            this.$toast('成功')
            if (common.getItem('jumpFrom') != undefined ) {
              appAction(STATION_JOIN_FINISHED)
            }else {
              this.$router.push({name: 'mysite', query:{role: common.getItem('userRole'),pageType: Home_Page}})
            }
            localStorage.removeItem('signZZ')
          }
          if(res.data && res.data.businessCode == 30061){
            this.show = true
            this.showMsg = res.data.businessMessage
          }
        }
        localStorage.removeItem('bank')
        localStorage.removeItem('bankVal')

        // 清除当前数据村情，站长信息缓存
        localStorage.removeItem(common.getItem('stationNo'))
      }).catch(err=>{
        console.log("ciowu;",err)
      })
    },
    togetDictByPage(){
      this.selectList = []
      getDictByPage('joinPage').then(res => {
      if (res.code === 200) {
        let data
        if (res.encryptedStatus === '1') {
          data = JSON.parse(common.decrypt(res.data, res.timestamp));
        } else {
          data = res.data
        }
        if (data.businessCode === 200) {
          let json = JSON.parse(data.data)
          this.selectAllList = json.coopBiz
          for(let obj of json.coopBiz){
            this.selectList.push(obj.dictValue)
            if(this.cooBizval == obj.dictKey){
              this.cooBiz = obj.dictValue
            }
          }

        }
      }
    });
    }
  },
  activated() {
    if(!this.fromFlag){
      this.getsiteInfo()
      // this.getPic()
      this.getBankList()
    }
    if(common.getItem('signZZ')){
      this.checked = true
    }else{
      this.checked = false
    }
  },
  mounted() {
    if (this.$route.query.jumpFrom != undefined ){
      this.$store.dispatch('keepAliveState/addKeepAliveAction', 'sitedetail')
    }
    if(common.getItem('signZZ')){
      this.checked = true
    }else{
      this.checked = false
    }
    this.togetJoinInfoByStationNo()
    this.getPic()
    this.getBankList()
    uwStatisticAction( '/site/sitedetail','站点详情')
  },
  beforeRouteLeave (to, from, next) {
    if (to.name == "partnerlist" || to.name == 'franchiseApplicationProtocol' || to.name == 'NewSiteRead' || to.name == 'siteInfo'){
    }else{
      this.$store.dispatch('keepAliveState/removeKeepAlive', 'sitedetail')
    }
    next()
  },
  destroyed() {
    localStorage.removeItem('bank')
    localStorage.removeItem('bankVal')
  },
  beforeRouteEnter(to, from, next) {
    // console.log('from:',from)
    if(from.name == 'NewSiteZZ'){
        next(vm => {
        vm.fromFlag = true
      });
    }else{
       next(vm => {
        vm.fromFlag = false
      });
    }
  },
}
</script>

<style lang="less" scoped>
.sitedetail{
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 1rem;
  .overlay-class{
    position: absolute;
    left: 0;
    top: 0;
    width:100%;
    min-height: 100%;
    z-index: 120;
    background: #f5f5f5;
  }
  .img-item{
    img{
      width: 1.2rem;
      height: 1.2rem;
      margin-right: 0.1rem;
      border-radius: 0.2rem;
    }

  }
  .flex{
    display: flex;
  }
  .dialog{
    font-size:0.36rem;
    border-radius: 0.16rem;
  }
  .title{
    align-items: center;
    justify-content: space-between;
    padding: 0.2rem 0.3rem;
    border-top: 0.1rem solid #3F7C53;
    background: #fff;
    .logo-img{
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 0.3rem;
    }
    .title_left{
      align-items: center;
    }
    .title_mid{
      margin-left: 0.3rem;
    }
  }
  .content{
    margin-top: 0.3rem;
  }
  .change-info{
    margin: 0.2rem 0.3rem;
  }
  .content-title{
    margin: 15px 0.3rem;
    font-size: 15px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    height: 15px;
    line-height: 16px;
    border-left:2px solid #3F7C53;
    padding-left: 4px;

  }
  .workNote{
    border-top: 1px solid #EEEEEE;
  }
  .content-desc{
    margin: 0.1rem 0.3rem;
    font-size: 0.26rem;
    color: #aaa;
    padding: 0 0 12px 0;
  }
  .card-upload{
    // margin: 0 0.3rem;
    height: 1.5rem;
    background: #fff;
    display: flex;
    align-items: center;
    padding: 0 0.2rem;
    overflow: hidden;
  }
  .agreement {
    padding: 38px 12px  30px 12px;
    font-size: 0.28rem;
    align-items: center;
    background: #F7F8F9;
    div{
      margin-left: 0.1rem;
    }
    span {
      color: #1989fa;
    }

  }
  .change-info{
    color: #3F7C53;
    justify-content: space-between;
    align-items: center;
    .block{
      width: 3.3rem;
      height: 1.2rem;
      border-radius: 8px;
      align-items: center;
      justify-content: center;
      div{
        margin-left:0.24rem;
        color: #555;
        font-size: 0.3rem;
      }
    }
    .siteblue-img{
      width: 0.48rem;
      height: 0.48rem;
    }
    .managerInfo-img{
      width: 0.48rem;
      height: 0.48rem;
    }
    .info-left{
      background: linear-gradient(142deg, rgba(0, 148, 255, 0.11) 0%, rgba(64, 146, 243, 0.89) 100%);
    }
    .info-right{
      background: linear-gradient(129deg, #F9FCFA 0%, #E5F7EB 100%);
    }
  }
  .footer{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0.88rem;
    .van-button{
      width: 100%;
    }
  }
  .line12{
    background: #F7F8F9;
    height: 12px;
  }
  .nextBtn{
    width: 100%;
    padding: 12px;
    background: #F7F8F9;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 100;
  }
}
</style>
<style>
  .van-picker__confirm {
    color: #3F7C53;
  }
  .van-picker__cancel{
    color: #3F7C53;
  }
  .siteDetailWarp .van-cell{
    padding: 14px 15px ;
    border-bottom: 1px solid #F7F8F9;
  }
  .siteDetailWarp .van-cell .van-field__label{

    font-size: 15px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 500;
    color: #333333;
  } .siteDetailWarp .van-cell .van-cell__value{

    font-size: 15px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 600;
    color: #333333;
  }
    .workNote .van-field__control{
      font-size: 15px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
    }
  .siteDetailWarp .van-icon-plus{
   color: #3F7C53;
    font-size: 20px;
    font-weight: bold;

  }
  .siteDetailWarp .van-uploader__preview{
    margin-right: 15px!important;
  }

</style>
